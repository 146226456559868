import { TranslationFunction } from 'i18next';
import { EditorPlatformApp } from '@wix/platform-editor-sdk';

import {
  aboutAppDefinitionId,
  aboutPageId,
  FOLLOWERS_APP_DEF_ID,
  FOLLOWERS_APP_WIDGET_ID,
} from './constants';

type GetAppManifest = NonNullable<EditorPlatformApp['getAppManifest']>;

type DePromisify<T> = T extends Promise<infer U> ? U : T;

type AppManifest = DePromisify<ReturnType<GetAppManifest>>;

export const manageFieldsEventId = 'manage-fields-clicked';

export const manifestFactory = (
  appDefinitionId: string,
  t: TranslationFunction,
): AppManifest => {
  switch (appDefinitionId) {
    case aboutAppDefinitionId:
      return {
        controllersStageData: {
          [aboutPageId]: {
            default: {
              gfpp: {
                desktop: {
                  // TODO: Types are incorrect mainAction2 requires optional property
                  mainAction2: {
                    actionId: manageFieldsEventId,
                    label: t('app.settings.main.secondary-cta'),
                  } as any,
                  iconButtons: {},
                },
                mobile: {
                  iconButtons: {},
                },
              },
            },
          },
        },
      };
    case FOLLOWERS_APP_DEF_ID:
      return {
        controllersStageData: {
          [FOLLOWERS_APP_WIDGET_ID]: {
            default: {
              behavior: {
                removable: false,
              },
              gfpp: {
                desktop: {
                  iconButtons: { widgetPlugins: 'HIDE' },
                },
                mobile: {
                  iconButtons: {},
                },
              },
            },
          },
        },
      };
    default:
      return {};
  }
};
